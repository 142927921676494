import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import StructuredData from '../components/structured-data'

function PrivacyPolicyPage({ data }) {
  const {
    wordpress: { page },
  } = data

  return (
    <Layout>
      <SEO title={page ? page.title : `Privacy Policy`} slug={page.uri} />
      <StructuredData
        pageType="webPage"
        slug="privacy-policy"
        pageTitle={page ? page.title : `Privacy Policy`}
      />
      <div className="container lg:max-w-4xl pb-10 lg:pb-20">
        <h1 className="text-3xl lg:text-5xl mb-6 lg:mb-10">{page.title}</h1>
        {page && page.content && (
          <div
            className="entry"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        )}
      </div>
    </Layout>
  )
}

PrivacyPolicyPage.propTypes = {
  data: PropTypes.object,
}

export default PrivacyPolicyPage

export const query = graphql`
  query {
    wordpress {
      page(id: "3", idType: DATABASE_ID) {
        title
        content
        uri
      }
    }
  }
`
